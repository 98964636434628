.homepageContainer {
  position: relative;
  overflow-x: hidden;
  height: 80vh;
}

.card-text {
  font-weight: 600;
  font-size: 1.5rem;
  color: rgb(72, 72, 155);
}

.card-title {
  margin-bottom: 0;
}

.card-text h5 {
  font-weight: 900;
  font-size: 1.5rem;
  color: rgb(6, 6, 7);
}

@font-face {
  font-family: rushk;
  src: url(../images/Rushk.otf);
}

.contentWrapper {
  display: grid;
  grid-template-columns: auto auto;
}

.footer {
  display: flex;
}


.card p {
  font-size: 1rem;
}

.emailmo {
  font-size: 1.4rem;
  font-weight: 600;
  color: aliceblue;
  font-family: 'Poppins', sans-serif;
}

.contact {
  margin-left: 22vw;
}

.proud img {
  border-radius: 21px;
}

.card {
  width: 50vw;
  margin-top: 5%;
  margin-bottom: 10%;
  z-index: 3;
  border-radius: 20px;
  background-color: rgb(215, 211, 211);
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  margin-top: 5vh;
}

.phone {
  position: absolute;
  border-radius: 12%;
  z-index: 2;
  margin-top: 30px;
}

.rightcircle1 {
  margin-right: 10px;
}

.listed_sports {
  display: flex;
}

@media screen and (min-width: 515px) and (max-width:600px) {



  .homepageContainer {
    height: 90vh;
  }

  .ppc {
    margin-left: 32px;
  }

  .rba {
    margin-left: 25px;
  }

  .Zimbabweh5 {
    margin-left: 18px;
  }

  .akola {
    margin-left: 11vw;
  }

  .card-img-top {
    width: 40vw;
    margin-right: 5vw;

  }

  .harare {
    margin-left: 3.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .twologo {
    text-align: center;
    margin-left: -25vw;
  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: 4vw;
  }

  .eci {
    margin-left: 13vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 0.9rem;
    margin-left: 14vw;
    margin-bottom: 10px;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 10vw;
    font-size: 22px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 17vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 28vw;
  }

  .player_corner_title {
    margin-left: 18vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    margin-left: -5vw;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .pot{
    margin-left: 20vw;
  }

  .rightcircle {
    width: 21px;
    height: auto;
  }

  .lefticon {
    width: 19px;
    height: auto;
  }



  .listf {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    margin-right: 15vw;
    display: flex;
  }

  .listf1 {
    font-size: 0.8rem;
    margin-right: 20vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 217px;
    border-radius: 3vh;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width:265px;
    border-radius: 4vh;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 60vh;
    margin-left: -36vw;

  }

  .contentk1 {
    margin-top: 75vh;
    margin-right: 4vw;
    margin-left: -60vw;

  }

  .supported_title {
    margin-left: 24vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: -1vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .bd,
  .lt {
    margin-left: 35vw;
  }

  .ltt,
  .bdt {
    margin-left: 48vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -20vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 18vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -10vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 26vw;
  }

  .molatest {
    margin-left: 21vw;
  }

  .email {
    margin-left: 10vw;
  }

  .flex {
    margin-left: 16vw;
  }


  .New_Delhi {
    margin-left: 8vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 12vw;
    font-size: 1.1rem;
  }
  .pba{
    margin-left: -5vw;
  }

  .startupI {
    margin-left: 4vw;
    font-weight: 600;
  }

  .rajcotguj {
    margin-left: 5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: 5.1vw;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;

  }


}
@media screen and (min-width: 600px) and (max-width:650px) {



  .homepageContainer {
    height: 90vh;
  }

  .ppc {
    margin-left: 32px;
  }

  .rba {
    margin-left: 25px;
  }

  .Zimbabweh5 {
    margin-left: 18px;
  }

  .akola {
    margin-left: 11vw;
  }

  .card-img-top {
    width: 40vw;
    margin-right: 5vw;

  }

  .harare {
    margin-left: 3.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .twologo {
    text-align: center;
    margin-left: -25vw;
  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: 4vw;
  }

  .eci {
    margin-left: 13vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 1.1rem;
    margin-left: 14vw;
    margin-bottom: 10px;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 10vw;
    font-size: 22px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 17vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 28vw;
  }

  .player_corner_title {
    margin-left: 18vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    margin-left: -5vw;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .pot{
    margin-left: 20vw;
  }

  .rightcircle {
    width: 21px;
    height: auto;
  }

  .lefticon {
    width: 19px;
    height: auto;
  }



  .listf {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    margin-right: 15vw;
    display: flex;
  }

  .listf1 {
    font-size: 0.8rem;
    margin-right: 20vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 217px;
    border-radius: 3vh;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width:265px;
    border-radius: 4vh;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 60vh;
    margin-left: -36vw;

  }

  .contentk1 {
    margin-top: 75vh;
    margin-right: 4vw;
    margin-left: -60vw;

  }

  .supported_title {
    margin-left: 24vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: -1vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .bd,
  .lt {
    margin-left: 35vw;
  }

  .ltt,
  .bdt {
    margin-left: 48vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -20vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 18vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -10vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 26vw;
  }

  .molatest {
    margin-left: 21vw;
  }

  .email {
    margin-left: 10vw;
  }

  .flex {
    margin-left: 16vw;
  }


  .New_Delhi {
    margin-left: 8vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 12vw;
    font-size: 1.1rem;
  }
  .pba{
    margin-left: -5vw;
  }

  .startupI {
    margin-left: 4vw;
    font-weight: 600;
  }

  .rajcotguj {
    margin-left: 5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: 5.1vw;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;

  }


}



/* complete */

@media screen and (max-width: 365px) {



  .homepageContainer {
    height: 90vh;
  }

  .card-img-top {
    width: 40vw;
    margin-right: 5vw;

  }



  .harare {
    margin-left: 3vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: -4.5vw;
  }

  .eci {
    margin-left: 13vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 0.9rem;
    margin-left:-22vw;
    margin-bottom: 10px;
    text-align: center;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 15vw;
    font-size: 17px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 9vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 20vw;
  }

  .player_corner_title {
    margin-left: 15vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    margin-left: -5vw;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .rightcircle {
    width: 21px;
    height: auto;
  }

  .lefticon {
    width: 19px;
    height: auto;
  }



  .listf {
    font-size: 0.8rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    margin-right: 15vw;
    display: flex;
  }

  .listf1 {
    font-size: 0.8rem;
    margin-right: 20vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 40vw;
    border-radius: 3vh;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width: 50vw;
    border-radius: 4vh;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 47vh;
    margin-left: -40vw;

  }

  .contentk1 {
    margin-top: 59vh;
    margin-right: 4vw;
    margin-left: -65vw;

  }

  .supported_title {
    margin-left: 17vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: -1vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .bd,
  .lt {
    margin-left: 35vw;
  }

  .ltt,
  .bdt {
    margin-left: 40vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -20vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 8vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }



  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -10vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 20vw;
  }

  .molatest {
    margin-left: 14vw;
  }

  .flex {
    margin-left: 12vw;
  }


  .New_Delhi {
    margin-left: 8vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 12vw;
    font-size: 1.1rem;
  }

  .startupI {
    margin-left: 4vw;
    font-weight: 600;
  }

  .rajcotguj {
    margin-left: 7.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .akola {
    margin-left: 3vw;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: -7vw;
  }

  .pba {
    margin-left: -3vw;
  }

  .rba {
    margin-left: -4vw;
  }

  .ppc {
    margin-left: -3vw;
  }

  .Zimbabweh5 span {
    margin-left: -10px;
  }

  .HPT {
    font-size: 0.8rem;
    font-weight: 400;
    color: white;
    margin-bottom: 0px;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;

  }

  .HPTP{
    margin-left: -13vw;
  }

  .twologo {
    text-align: center;
    margin-left: -24vw;
  }


}

@media screen and (min-width: 365px) and (max-width: 415px) {



  .homepageContainer {
    height: 90vh;
  }

  .harare {
    margin-left: 3vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .akola {
    margin-left: 2.5vw;
  }

  .twologo {
    text-align: center;
    margin-left: -25vw;
  }

  .card-img-top {
    width: 40vw;
    margin-right: 5vw;

  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: -2vw;
  }

  .eci {
    margin-left: 13vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 1.1rem;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 10vw;
    font-size: 17px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 14vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 20vw;
  }

  .player_corner_title {
    margin-left: 18vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    margin-left: -5vw;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .rightcircle {
    width: 21px;
    height: auto;
  }

  .lefticon {
    width: 19px;
    height: auto;
  }



  .listf {
    font-size: 0.8rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    margin-right: 15vw;
    display: flex;
  }

  .listf1 {
    font-size: 0.8rem;
    margin-right: 20vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 40vw;
    border-radius: 3vh;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width: 50vw;
    border-radius: 4vh;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 47vh;
    margin-left: -40vw;

  }

  .contentk1 {
    margin-top: 59vh;
    margin-right: 4vw;
    margin-left: -65vw;

  }

  .supported_title {
    margin-left: 19vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: -1vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .bd,
  .lt {
    margin-left: 35vw;
  }

  .ltt,
  .bdt {
    margin-left: 40vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -20vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 18vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -10vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 20vw;
  }

  .molatest {
    margin-left: 14vw;
  }

  .flex {
    margin-left: 12vw;
  }


  .New_Delhi {
    margin-left: 8vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 12vw;
    font-size: 1.1rem;
  }

  .startupI {
    margin-left: 4vw;
    font-weight: 600;
  }

  .rajcotguj {
    margin-left: 6vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: -10px;
  }

  .pba {
    margin-left: -10px;
  }

  .HPTP {
    margin-left: -12vw;
  }

  .Zimbabweh5 span {
    margin-left: -10px;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;

  }


}


@media screen and (min-width: 415px) and (max-width:515px) {



  .homepageContainer {
    height: 90vh;
  }

  .ppc {
    margin-left: 18px;
  }

  .rba {
    margin-left: 18px;
  }

  .Zimbabweh5 {
    margin-left: 18px;
  }

  .akola {
    margin-left: 5vw;
  }

  .card-img-top {
    width: 40vw;
    margin-right: 5vw;

  }

  .harare {
    margin-left: 3.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .twologo {
    text-align: center;
    margin-left: -25vw;
  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: -2vw;
  }

  .eci {
    margin-left: 13vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 1.1rem;
    margin-left: 14vw;
    margin-bottom: 10px;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 10vw;
    font-size: 17px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 17vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 28vw;
  }

  .player_corner_title {
    margin-left: 18vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 35px;
    text-align: center;
    margin-left: -5vw;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .rightcircle {
    width: 21px;
    height: auto;
  }

  .lefticon {
    width: 19px;
    height: auto;
  }



  .listf {
    font-size: 0.8rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    margin-right: 15vw;
    display: flex;
  }

  .listf1 {
    font-size: 0.8rem;
    margin-right: 20vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 40vw;
    border-radius: 3vh;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width: 50vw;
    border-radius: 4vh;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 60vh;
    margin-left: -40vw;

  }

  .contentk1 {
    margin-top: 75vh;
    margin-right: 4vw;
    margin-left: -65vw;

  }

  .supported_title {
    margin-left: 19vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: -1vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .bd,
  .lt {
    margin-left: 35vw;
  }

  .ltt,
  .bdt {
    margin-left: 48vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -20vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 18vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -10vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 26vw;
  }

  .molatest {
    margin-left: 21vw;
  }

  .email {
    margin-left: 10vw;
  }

  .flex {
    margin-left: 16vw;
  }


  .New_Delhi {
    margin-left: 8vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 12vw;
    font-size: 1.1rem;
  }

  .startupI {
    margin-left: 4vw;
    font-weight: 600;
  }

  .rajcotguj {
    margin-left: 6vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: 1.1vw;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;

  }


}

@media screen and (min-width: 650px) and (max-width:800px) {



  .homepageContainer {
    height: 90vh;
  }

  .card-img-top {
    width: 30vw;
    margin-right: 5vw;

  }

  .squash {
    margin-left: 7vw;
  }

  .aiciit {
    margin-left: 6vw;
  }

  .eci {
    margin-left: 17vw;
  }

  .ppc{
    margin-left: 12vw;
  }

  .rba{
    margin-left: 10vw;
  }

  .card-title {
    text-align: start;
  }

  .proudbody p {
    margin-right: 10vw;
  }

  .allright {
    font-size: 1.1rem;
    margin-left: 14vw;
    margin-bottom: 10px;
  }

  .banner_buttonP img {
    width: 45vw;
  }

  .banner_buttonA img {
    width: 35vw;
    border: 1px solid gainsboro;
    border-radius: 7px;
  }

  .listed_sports {
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
  }

  .animated-text {
    font-weight: 800;
    margin-right: 5vw;
    font-size: 30px;
    margin-bottom: 0px;
    font-family: rushk;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-right: 15vw;
    margin-bottom: 35px;
    margin-left: 14vw;
    font-family: rushk;
  }

  .listed_sports_title {
    margin-left: 34vw;
  }

  .player_corner_title {
    margin-left: 33vw;
  }

  .animated-text3 {
    font-weight: 800;
    font-size: 20px;
    margin-left: 8vw;
    margin-bottom: 35px;
    text-align: center;
    font-family: rushk;
  }

  .animated-text2 {

    font-size: 17px;

  }

  .rightcircle {
    width: 30px;
    height: auto;
  }

  .lefticon {
    width: 25px;
    height: auto;
  }



  .listf {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 10vw;
    font-family: 'Poppins', sans-serif;
    display: flex;
  }

  .listf1 {
    font-size: 1.5rem;
    margin-right: 0vw;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
    display: flex;
  }

  .cardsport {
    transform: rotateY(360deg);
    transition: 0.1s;
  }

  .card p {
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
  }

  .card {
    width: 90vw;
    margin-bottom: 10%;
    margin-left: -5vw;
    z-index: 3;
    border-radius: 10px;
    background-color: rgb(215, 211, 211);
    transition: 0.9s;
  }

  .leftContent {
    font-size: 1.6rem;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    display: inline;
    padding: 2.3rem !important;
    margin-top: 10px;
    padding-top: 0;
  }




  .phone {
    width: 80vw;
    margin-bottom: 10px;
    position: relative;
    right: 10vw;
    border-radius: 12%;
    z-index: 2;
  }

  .knockout video {
    width: 35vw;
    border-radius: 5vh;
    margin-left: 6vw;

  }

  .knockout2 {
    margin-left: 15vw;
  }

  .knockout2 video {
    width: 40vw;
    border-radius: 5vh;
    margin-left: 10vw;
  }

  .knockout h4 {
    font-size: 0.9rem;
    font-family: 'rushk';
    font-weight: 400;
    text-align: center;
  }

  .news {
    font-size: small;
  }

  .follow {
    font-size: smaller
  }

  .footer {
    flex-direction: column;
  }

  .banner_buttons1 {
    margin-left: 15vw;
  }

  .knockout1 {
    margin-left: 41vw;
    position: absolute;
  }

  .contentk {
    margin-top: 90vh;
    margin-left: -40vw;

  }

  .contentk1 {
    margin-top: 100vh;
    margin-right: 4vw;
    margin-left: -65vw;

  }

  .supported_title {
    margin-left: 32vw;
  }

  .banner_buttons {
    align-items: center;
    margin-top: 0px;
    margin-left: 3vw;
  }

  .proud {
    margin-left: 15vw;
    margin-bottom: 10vh;

  }

  .proud img {
    width: 50vw;
  }

  .pot {
    margin-left: 31vw;
  }

  .bd,
  .lt {
    margin-left: 55vw;
  }

  .ltt,
  .bdt {
    margin-left: 62vw;
  }

  .banner_button {
    width: 140px;
    margin-left: 7px;
    padding: 0.6rem 0.2rem;
  }

  .LISTED-SPORTS {
    margin-left: 15vw;
  }

  .rightContent {
    visibility: hidden;
  }

  .rightContent1 {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    opacity: 0.7;
  }

  .exploreT {
    background-color: #D15858;
    margin-left: -5vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT1 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 14vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT2 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: 32vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .clogo {
    width: 10vw;
    margin-left: 5px;
  }

  .brand-name1 {
    width: 125px;
    height: auto;
    margin-left: -8vw;

  }


  .logo2 {
    width: 100px;
    height: auto;
  }



  .contact {
    margin-left: 1vw;
  }

  .contact h4 {
    margin-left: 35vw;
  }

  .molatest {
    margin-left: 32vw;
  }

  .email {
    margin-left: 10vw;
  }

  .flex {
    margin-left: 22vw;
  }


  .New_Delhi {
    margin-left: 4vw;
    font-size: 1.1rem;
  }

  .kerala {
    margin-left: 6vw;
    font-size: 1.1rem;
  }

  .startupI {
    margin-left: 15vw;
    font-weight: 600;
  }
.akola{
  margin-left: 93px;
}
  .rajcotguj {
    margin-left: 3vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .Zimbabwespan {
    margin-right: 50px;
  }

  .Zimbabweh5 {
    margin-left: 11vw;
  }

  .Zimbabweh5 span {
    margin-left: -10px;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 9vw;
    font-family: 'Poppins', sans-serif;


  }

  .atbottom{
    margin-left: 10vw;
  }

 .email{
  margin-left: 24vw;
 }

  .twologo {
    text-align: center;
    margin-left: -24vw;
  }


}


@media screen and (min-width:800px) and (max-width:900px) {

  .homepageContainer {
    height: 86vh;
  }

  .harare {
    margin-left: -1.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .ppc {
    margin-left: -1vw;
  }

  .rba {
    margin-left: -1vw;
  }

  .pba {
    margin-left: -3rem;
  }

  .Zimbabweh5 {
    margin-left: -1vw;
  }

  .akola {
    margin-left: 1.2vw;
  }


  .startupI {
    margin-left: 0vw;
    font-weight: 600;
  }

  .Zimbabwespan {
    margin-right: 47px;
  }

  .banner_buttonP img {
    width: 200px;

  }

  .banner_buttonP {
    margin-right: 20px;
  }

  .squash {
    margin-left: 3vw;
  }

  .banner_buttonA img {
    width: 156px;
    border-radius: 10px;
    margin-left: 22px;
    border: 2px rgb(127 126 126) solid;
  }

  .aiciit {
    margin-left: -1vw;
  }

  .eci {
    margin-left: 3vw;
  }

  .contentk {
    margin-top: -1vh;
    margin-left: -6.1vw;
  }

  .clogo {
    width: 3vw;
    margin-left: 5px;
  }

  .knockout video {
    height: 35vw;
    margin-top: -9vh;
    border-radius: 5vh;
    margin-right: 8vw;
  }

  .knockout h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    font-weight: 600;
    text-align: start;
  }


  .knockout2 video {
    height: 35vw;
    border-radius: 5vh;
    margin-top: -10vh;
  }


  .brand-name1 {
    width: 200px;
    height: auto;
    margin-left: -2%;
  }


  .logo2 {
    width: 150px;
    height: auto;
  }

  .knockout2 h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    margin-left: -2vw;
  }

  .contentk1 {
    margin-top: 0vh;
    margin-left: -2vw;

  }


  .exploreT {
    background-color: #D15858;
    margin-left: -9vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: monospace;
    font-weight: 800;
  }

  .proud {
    width: 25vw;
    height: 40vh;
    margin-left: 3vw;
    margin-bottom: 10vh;

  }

  .exploreT1 {
    background-color: #D15858;
    margin-left: 12vw;
    border-radius: 12vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .ltt,
  .bdt {
    margin-left: 2vw;
  }

  .lefticon {
    width: 18px;
    margin-left: 5px;
    height: auto;
  }

  .rightContent1 {
    visibility: hidden;
  }

  .LISTED-SPORTS {
    margin-left: 27vw;
  }

  .rightcircle {
    width: 24px;
    height: auto;
  }

  .card-img-top {
    width: 60%;
    margin-right: 5vw;
  }

  .New_Delhi {
    margin-left: 2vw;
    font-size: 0.9rem;
  }

  .New_Delhi {
    margin-left: 3vw;
    font-size: 0.9rem;
  }

  .listf {
    font-size: 1rem;
    display: flex;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -1px;
  }

  .listf1 {
    font-size: 1.1rem;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -11px;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 5vh;
    font-family: rushk;
    text-align: center;
    margin-left: -8vw;
  }

  .animated-text {
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 50px;
    font-family: rushk;
    margin-left: 5px;
  }

  .phone {
    height: 33vw;
    position: absolute;
    right: 4vw;
    border-radius: 12%;
    z-index: 2;
  }

  .card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  .allright {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: -5vw;
    text-align: center;
  }

  .banner_buttons1 {
    margin-left: -1vw;
  }

  .contact {
    margin-top: 60px;
    margin-left: 5vw;
  }


  .leftContent {
    margin-left: 10vw;
    margin-right: 5vw;
    font-size: 2.1rem;
    margin-top: 9vh;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    padding-top: 0;
    position: absolute;
  }

  .rajcotguj {
    margin-left: 1.7vw;
    font-size: 1rem;
    margin-top: -5px;
  }

  .HPT {
    font-size: 0.9rem;
    font-weight: 400;
    color: white;
    margin-right: 5vw;
    font-family: 'Poppins', sans-serif;
  }

  .HPTP {
    text-align: center;
  }

  .New_Delhi {
    margin-left: 2vw;
  }

  .kerala {
    margin-left: 3vw;
  }
}

@media screen and (min-width:900px) and (max-width:1000px) {

  .homepageContainer {
    height: 86vh;
  }

  .harare {
    margin-left: -1.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .ppc {
    margin-left: -1vw;
  }

  .rba {
    margin-left: -1vw;
  }

  .pba {
    margin-left: -3vw;
  }

  .Zimbabweh5 {
    margin-left: -1vw;
  }

  .akola {
    margin-left: 1.2vw;
  }


  .startupI {
    margin-left: 0vw;
    font-weight: 600;
  }

  .Zimbabwespan {
    margin-right: 47px;
  }

  .banner_buttonP img {
    width: 260px;

  }

  .banner_buttonP {
    margin-right: 20px;
  }

  .squash {
    margin-left: 3vw;
  }

  .banner_buttonA img {
    width: 210px;
    border-radius: 10px;
    border: 2px rgb(127 126 126) solid;
  }

  .aiciit {
    margin-left: -1vw;
  }

  .eci {
    margin-left: 3vw;
  }

  .contentk {
    margin-top: -1vh;
    margin-left: -6.1vw;
  }

  .clogo {
    width: 3vw;
    margin-left: 5px;
  }

  .knockout video {
    height: 35vw;
    margin-top: -9vh;
    border-radius: 5vh;
    margin-right: 8vw;
  }

  .knockout h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    font-weight: 600;
    text-align: start;
  }


  .knockout2 video {
    height: 35vw;
    border-radius: 5vh;
    margin-top: -10vh;
  }


  .brand-name1 {
    width: 200px;
    height: auto;
    margin-left: -2%;
  }


  .logo2 {
    width: 150px;
    height: auto;
  }

  .knockout2 h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    margin-left: -2vw;
  }

  .contentk1 {
    margin-top: 0vh;
    margin-left: -2vw;

  }


  .exploreT {
    background-color: #D15858;
    margin-left: -9vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: monospace;
    font-weight: 800;
  }

  .proud {
    width: 25vw;
    height: 40vh;
    margin-left: 3vw;
    margin-bottom: 10vh;

  }

  .exploreT1 {
    background-color: #D15858;
    margin-left: 12vw;
    border-radius: 12vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .ltt,
  .bdt {
    margin-left: 2vw;
  }

  .lefticon {
    width: 30px;
    margin-left: 5px;
    height: auto;
  }

  .rightContent1 {
    visibility: hidden;
  }

  .LISTED-SPORTS {
    margin-left: 27vw;
  }

  .rightcircle {
    width: 40px;
    height: auto;
  }

  .card-img-top {
    width: 60%;
    margin-right: 5vw;
  }

  .New_Delhi {
    margin-left: 2vw;
    font-size: 0.9rem;
  }

  .New_Delhi {
    margin-left: 3vw;
    font-size: 0.9rem;
  }

  .listf {
    font-size: 1.1rem;
    display: flex;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -1px;
  }

  .listf1 {
    font-size: 1.1rem;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -11px;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 5vh;
    font-family: rushk;
    text-align: center;
    margin-left: -8vw;
  }

  .animated-text {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 50px;
    font-family: rushk;
    margin-left: 5px;
  }

  .phone {
    height: 33vw;
    position: absolute;
    right: 4vw;
    border-radius: 12%;
    z-index: 2;
  }

  .card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  .allright {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: -5vw;
    text-align: center;
  }

  .banner_buttons1 {
    margin-left: -1vw;
  }

  .contact {
    margin-top: 60px;
    margin-left: 5vw;
  }


  .leftContent {
    margin-left: 10vw;
    margin-right: 5vw;
    font-size: 2.1rem;
    margin-top: 9vh;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    padding-top: 0;
    position: absolute;
  }

  .rajcotguj {
    margin-left: 1.7vw;
    font-size: 1rem;
    margin-top: -5px;
  }

  .HPT {
    font-size: 0.9rem;
    font-weight: 400;
    color: white;
    margin-right: 5vw;
    font-family: 'Poppins', sans-serif;
  }

  .HPTP {
    text-align: center;
  }

  .New_Delhi {
    margin-left: 2vw;
  }

  .kerala {
    margin-left: 3vw;
  }
}

@media screen and (min-width:1000px) and (max-width:1150px) {

  .homepageContainer {
    height: 86vh;
  }

  .harare {
    margin-left: -1.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .ppc {
    margin-left: 0.7vw;
  }

  .rba {
    margin-left: 0vw;
  }

  .pba {
    margin-left: -3vw;
  }

  .Zimbabweh5 {
    margin-left: 0.8vw;
  }

  .akola {
    margin-left: 1.2vw;
  }


  .startupI {
    margin-left: 0vw;
    font-weight: 600;
  }

  .Zimbabwespan {
    margin-right: 47px;
  }

  .banner_buttonP img {
    width: 260px;

  }

  .banner_buttonP {
    margin-right: 20px;
  }

  .squash {
    margin-left: 3vw;
  }

  .banner_buttonA img {
    width: 210px;
    border-radius: 10px;
    border: 2px rgb(127 126 126) solid;
  }

  .aiciit {
    margin-left: -1vw;
  }

  .eci {
    margin-left: 3vw;
  }

  .contentk {
    margin-top: -1vh;
    margin-left: -6.1vw;
  }

  .clogo {
    width: 3vw;
    margin-left: 5px;
  }

  .knockout video {
    height: 35vw;
    margin-top: -9vh;
    border-radius: 5vh;
    margin-right: 8vw;
  }

  .knockout h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    font-weight: 600;
    text-align: start;
  }


  .knockout2 video {
    height: 35vw;
    border-radius: 5vh;
    margin-top: -10vh;
  }


  .brand-name1 {
    width: 200px;
    height: auto;
    margin-left: -2%;
  }


  .logo2 {
    width: 150px;
    height: auto;
  }

  .knockout2 h4 {
    font-size: 1.1rem;
    font-family: 'rushk';
    margin-left: -2vw;
  }

  .contentk1 {
    margin-top: 0vh;
    margin-left: -2vw;

  }


  .exploreT {
    background-color: #D15858;
    margin-left: -9vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: monospace;
    font-weight: 800;
  }

  .proud {
    width: 25vw;
    height: 40vh;
    margin-left: 3vw;
    margin-bottom: 10vh;

  }

  .exploreT1 {
    background-color: #D15858;
    margin-left: 12vw;
    border-radius: 12vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .ltt,
  .bdt {
    margin-left: 2vw;
  }

  .lefticon {
    width: 30px;
    margin-left: 5px;
    height: auto;
  }

  .rightContent1 {
    visibility: hidden;
  }

  .LISTED-SPORTS {
    margin-left: 27vw;
  }

  .rightcircle {
    width: 40px;
    height: auto;
  }

  .card-img-top {
    width: 60%;
    margin-right: 5vw;
  }

  .New_Delhi {
    margin-left: 2vw;
    font-size: 0.9rem;
  }

  .New_Delhi {
    margin-left: 3vw;
    font-size: 0.9rem;
  }

  .listf {
    font-size: 1.1rem;
    display: flex;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -1px;
  }

  .listf1 {
    font-size: 1.1rem;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -13px;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 5vh;
    font-family: rushk;
    text-align: center;
    margin-left: -8vw;
  }

  .animated-text {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 50px;
    font-family: rushk;
    margin-left: 5px;
  }

  .phone {
    height: 33vw;
    position: absolute;
    right: 4vw;
    border-radius: 12%;
    z-index: 2;
  }

  .card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  .allright {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: -5vw;
    text-align: center;
  }

  .banner_buttons1 {
    margin-left: -1vw;
  }

  .contact {
    margin-top: 60px;
    margin-left: 5vw;
  }


  .leftContent {
    margin-left: 10vw;
    margin-right: 5vw;
    font-size: 2.1rem;
    margin-top: 9vh;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    padding-top: 0;
    position: absolute;
  }

  .rajcotguj {
    margin-left: 1.3vw;
    font-size: 1rem;
    margin-top: -5px;
  }

  .HPT {
    font-size: 0.9rem;
    font-weight: 400;
    color: white;
    margin-right: 5vw;
    font-family: 'Poppins', sans-serif;
  }

  .HPTP {
    text-align: center;
  }

  .New_Delhi {
    margin-left: 2vw;
  }

  .kerala {
    margin-left: 3vw;
  }
}

@media screen and (min-width:1150px) and (max-width:1350px) {

  .homepageContainer {
    height: 86vh;
  }


  
  .pba{
    margin-left: -3vw;
  }


  .akola {
    margin-left: 1.5vw;
  }

  .Zimbabwespan {
    margin-right: 60px;
  }

  .startupI {
    margin-left: 1vw;
    font-weight: 600;
  }

  .Zimbabwespan {
    margin-right: 60px;
  }

  .banner_buttonP img {
    width: 260px;

  }

  .banner_buttonP {
    margin-right: 20px;
  }

  .squash {
    margin-left: 3vw;
  }

  .banner_buttonA img {
    width: 210px;
    border-radius: 10px;
    border: 2px rgb(127 126 126) solid;
  }

  .aiciit {
    margin-left: -1vw;
  }

  .eci {
    margin-left: 3vw;
  }

  .contentk {
    margin-top: -1vh;
    margin-left: -6.1vw;
  }

  .clogo {
    width: 3vw;
    margin-left: 5px;
  }

  .knockout video {
    height: 35vw;
    margin-top: -9vh;
    border-radius: 5vh;
    margin-right: 8vw;
  }

  .knockout h4 {
    font-size: 1.3rem;
    font-family: 'rushk';
    font-weight: 600;
    text-align: start;
  }


  .knockout2 video {
    height: 35vw;
    border-radius: 5vh;
    margin-top: -10vh;
  }


  .brand-name1 {
    width: 200px;
    height: auto;
    margin-left: -2%;
  }


  .logo2 {
    width: 150px;
    height: auto;
  }

  .knockout2 h4 {
    font-size: 1.3rem;
    font-family: 'rushk';
    margin-left: -2vw;
  }

  .contentk1 {
    margin-top: 0vh;
    margin-left: -2vw;

  }


  .exploreT {
    background-color: #D15858;
    margin-left: -9vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1rem;
    font-family: monospace;
    font-weight: 800;
  }

  .proud {
    width: 25vw;
    height: 40vh;
    margin-left: 3vw;
    margin-bottom: 10vh;

  }

  .exploreT1 {
    background-color: #D15858;
    margin-left: 12vw;
    border-radius: 12vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .ltt,
  .bdt {
    margin-left: 2vw;
  }

  .lefticon {
    width: 30px;
    margin-left: 5px;
    height: auto;
  }

  .rightContent1 {
    visibility: hidden;
  }

  .LISTED-SPORTS {
    margin-left: 27vw;
  }

  .rightcircle {
    width: 40px;
    height: auto;
  }

  .card-img-top {
    width: 60%;
    margin-right: 5vw;
  }

  .New_Delhi {
    margin-left: 2vw;
    font-size: 0.8rem;
  }

  .New_Delhi {
    margin-left: 3vw;
    font-size: 0.8rem;
  }

  .harare {
    margin-left: 0.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .listf {
    font-size: 1.3rem;
    display: flex;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -1px;
  }

  .listf1 {
    font-size: 1.3rem;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 35px;
    margin-bottom: 5vh;
    font-family: rushk;
    text-align: center;
    margin-left: -8vw;
  }

  .animated-text {
    font-weight: 800;
    font-size: 35px;
    margin-bottom: 50px;
    font-family: rushk;
    margin-left: 5px;
  }

  .phone {
    height: 33vw;
    position: absolute;
    right: 4vw;
    border-radius: 12%;
    z-index: 2;
  }

  .card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
  }

  .allright {
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: -5vw;
    text-align: center;
  }

  .banner_buttons1 {
    margin-left: -1vw;
  }

  .contact {
    margin-top: 60px;
    margin-left: 5vw;
  }


  .leftContent {
    margin-left: 10vw;
    margin-right: 5vw;
    font-size: 2.1rem;
    margin-top: 9vh;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    padding-top: 0;
    position: absolute;
  }

  .rajcotguj {
    margin-left: 1.7vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .HPT {
    font-size: 0.8rem;
    font-weight: 400;
    color: white;
    margin-right: 5vw;
    font-family: 'Poppins', sans-serif;
  }

  .HPTP {
    text-align: center;
  }

  .New_Delhi {
    margin-left: 2vw;
  }

  .kerala {
    margin-left: 3vw;
  }
}

@media screen and (min-width:1350px) {

  .homepageContainer {
    height: 86vh;
  }


  .harare {
    margin-left: -0.5vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .ppc{
    margin-left: 1vw;
  }

  .rba{
    margin-left: 1vw;
  }

  .Zimbabweh5{
    margin-left: 2vw;
  }

  .pba{
    margin-left: -3vw;
  }
  .akola{
    margin-left: 3.4vw;
  }

  .Zimbabwespan {
    margin-right: 60px;
  }

  .startupI {
    margin-left: 1vw;
    font-weight: 600;
  }

  .Zimbabwespan {
    margin-right: 60px;
  }

  .banner_buttonP img {
    width: 260px;

  }

  .banner_buttonP {
    margin-right: 20px;
  }

  .squash {
    margin-left: 3vw;
  }

  .banner_buttonA img {
    width: 210px;
    border-radius: 10px;
    border: 2px rgb(127 126 126) solid;
  }

  .aiciit {
    margin-left: 0vw;
  }

  .eci {
    margin-left: 3vw;
  }

  .contentk {
    margin-top: -1vh;
    margin-left: -6.1vw;
  }

  .clogo {
    width: 3vw;
    margin-left: 5px;
  }

  .knockout video {
    height: 30vw;
    margin-top: -10vh;
    border-radius: 5vh;
    margin-right: 8vw;
  }

  .knockout h4 {
    font-size: 1.6rem;
    font-family: 'rushk';
    font-weight: 600;
    text-align: start;
  }


  .knockout2 video {
    height: 30vw;
    border-radius: 5vh;
    margin-top: -10vh;
  }


  .brand-name1 {
    width: 200px;
    height: auto;
    margin-left: -2%;
  }


  .logo2 {
    width: 150px;
    height: auto;
  }

  .knockout2 h4 {
    font-size: 1.6rem;
    font-family: 'rushk';
    margin-left: -2vw;
  }

  .contentk1 {
    margin-top: 0vh;
    margin-left: -5vw;

  }


  .exploreT {
    background-color: #D15858;
    margin-left: -9vw;
    border-radius: 13px;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }

  .exploreT3 {
    background-color: #D15858;
    border-radius: 13px;
    margin-left: -3vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.2rem;
    font-family: monospace;
    font-weight: 800;
  }


  .proud {
    width: 25vw;
    height: 40vh;
    margin-left: 3vw;
    margin-bottom: 10vh;

  }

  .exploreT1 {
    background-color: #D15858;
    margin-left: 12vw;
    border-radius: 12vw;
    padding: 5px 15px 5px 15px;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .ltt,
  .bdt {
    margin-left: 2vw;
  }

  .lefticon {
    width: 30px;
    margin-left: 5px;
    height: auto;
  }

  .rightContent1 {
    visibility: hidden;
  }

  .LISTED-SPORTS {
    margin-left: 27vw;
  }

  .rightcircle {
    width: 40px;
    height: auto;
  }

  .card-img-top {
    width: 60%;
    margin-right: 5vw;
  }



  .New_Delhi {
    margin-left: 3vw;
    font-size: 1.1rem;
  }

  .listf {
    font-size: 1.6rem;
    display: flex;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 6px;
  }

  .listf1 {
    font-size: 1.6rem;
    font-weight: 400;
    display: flex;
    font-family: 'Poppins', sans-serif;
  }

  .animated-text1 {
    font-weight: 800;
    font-size: 35px;
    margin-bottom: 5vh;
    font-family: rushk;
    text-align: center;
    margin-left: -8vw;
  }

  .animated-text {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 50px;
    font-family: rushk;
    margin-left: 5px;
  }

  .phone {
    height: 33vw;
    position: absolute;
    right: 4vw;
    border-radius: 12%;
    z-index: 2;
  }

  .card-title {
    font-family: 'Poppins', sans-serif;
  }

  .allright {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-left: -5vw;
    text-align: center;
  }

  .banner_buttons1 {
    margin-left: -1vw;
  }

  .contact {
    margin-top: 60px;
    margin-left: 5vw;
  }


  .leftContent {
    margin-left: 10vw;
    margin-right: 5vw;
    font-size: 3rem;
    margin-top: 9vh;
    z-index: 2;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    padding-top: 0;
    position: absolute;
  }

  .rajcotguj {
    margin-left: 1.2vw;
    font-size: 1.1rem;
    margin-top: -5px;
  }

  .HPT {
    font-size: 1rem;
    font-weight: 400;
    color: white;
    margin-right: 5vw;
    font-family: 'Poppins', sans-serif;
  }

  .HPTP {
    text-align: center;
  }

  .New_Delhi {
    margin-left: 2vw;
  }

  .kerala {
    margin-left: 3vw;
    font-size: 1.1rem;
  }
}