@media screen and (max-width: 800px) {
    .navigation {
        height: 75px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        /* background-color: rgb(31, 29, 29); */
        color: rgb(47, 45, 45);
        /* box-shadow: 0 2px 2px 2px rgba(141, 2, 2, 0.23); */
    }

    .brand-name {
        width: 115px;
        height: auto;
        margin-left: -6%;
    }


    .logo {
        width: 80px;
        height: auto;
        margin-left: 3vw;
    }

    .logo1 {
        width: 40px;
        height: auto;
    }

}

@media screen and (min-width:800px) {
    .navigation {
        height: 90px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 3.5rem 0rem;
        /* background-color: rgb(31, 29, 29); */
        color: rgb(47, 45, 45);
        /* box-shadow: 0 2px 2px 2px rgba(141, 2, 2, 0.23); */
    }

    .brand-name {
        width: 150px;
        height: auto;
        margin-left: -2%;
    }


    .logo {
        width: 120px;
        height: auto;
        margin-left: 11vw;
    }

    .logo1 {
        width: 40px;
        height: auto;
    }
}



.navigation-menu {
    margin-left: auto;
    margin-right: 11vw;
}

.navigation-menu ul {
    display: flex;
    margin-top: 2rem;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    margin: 0 1.1rem;
}

/* Your existing styles for the header container, navigation menu, and other elements */

.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
    top: 10;
    font-size: 1.4rem;
    margin-right: 0vw;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    position: relative;
}


.header1::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 3px;
    width: 0;
    background-color: #da3845;
    transition: width 0.3s ease;
}

.navigation-menu li a:hover::before {
    width: 100%;
}

.header {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #da3845;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.header:hover {
    background-color: #da3845;
}

@media screen and (max-width: 1053px) {
    .header {
        display: block;
    }
}

@media screen and (max-width: 1053px) {
    .navigation-menu ul {
        display: none;
    }
}

@media screen and (max-width: 1053px) {
    .navigation-menu.expanded ul {
        display: block;
    }
}

@media screen and (max-width: 1053px) {
    .navigation-menu ul {
        position: absolute;
        right: 0;
        flex-direction: column;
        width: 60%;
        height: 100vh;
        background-color: transparent;
        z-index: 3;
    }

    .navigation-menu ul{
        background-color: rgba(4, 4, 4, 0.728)
    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        color:white;
        width: 100%;
        padding: 1.5rem 0;
    }

}