body {
    background-image: url('https://res.cloudinary.com/darvxwmt8/image/upload/v1716221694/Website/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.privay h5  {
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.privay h4  {
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;

}

.terms h4{
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.terms p{
    color: white;
    padding-left: 10vw;
    padding-right: 10vw;
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
}

